/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { styled } from '@mui/material/styles';
import Typography, { type TypographyProps } from '@mui/material/Typography';

import sidekick from '@last-rev/contentful-sidekick-util';

import ErrorBoundary from '../ErrorBoundary';
import ContentModule from '../ContentModule';

import type { TextProps, TextOwnerState } from './Text.types';
import Grid from '../Grid';

const Text = (props: TextProps) => {
  const ownerState = { ...props };

  const {
    body,
    rteOverline: overline,
    rteTitle: title,
    rteSubtitle: subtitle,
    variant,
    sidekickLookup,
    sx,
    className
  } = props;

  return (
    <ErrorBoundary>
      <Root
        data-testid="Text-root"
        {...sidekick(sidekickLookup)}
        ownerState={ownerState}
        className={className}>
        {!!overline && (
          <Overline
            {...sidekick(sidekickLookup, 'overline')}
            body={overline}
            __typename="RichText"
            data-testid="Text-overline"
            variant="overline"
            ownerState={ownerState}
          />
        )}

        {!!title && (
          <Title
            {...sidekick(sidekickLookup, 'title')}
            body={title}
            __typename="RichText"
            data-testid="Text-title"
            variant="h1"
            component="h3"
            ownerState={ownerState}
          />
        )}

        {!!subtitle && (
          <Subtitle
            body={subtitle}
            {...sidekick(sidekickLookup, 'subtitle')}
            __typename="RichText"
            data-testid="Text-subtitle"
            ownerState={ownerState}
            variant="h4"
            component="h4"
          />
        )}

        {!!body && (
          <ContentModule
            body={body}
            sidekickLookup={sidekickLookup}
            variant={variant}
            {...props}
            __typename="RichText"
            ownerState={ownerState}
          />
        )}
      </Root>
    </ErrorBoundary>
  );
};

// Support for \n in text
const Root = styled(Grid, {
  name: 'Text',
  slot: 'Root',
  overridesResolver: (_, styles) => [styles.root]
})<{ ownerState: TextOwnerState }>``;

const Overline = styled(ContentModule, {
  name: 'Text',
  slot: 'Overline',
  overridesResolver: (_, styles) => [styles.overline]
})<{ ownerState: TextOwnerState }>``;

const Title = styled(ContentModule, {
  name: 'Text',
  slot: 'Title',
  overridesResolver: (_, styles) => [styles.title]
})<{ ownerState: TextOwnerState }>``;

const Subtitle = styled(ContentModule, {
  name: 'Text',
  slot: 'Subtitle',
  overridesResolver: (_, styles) => [styles.subtitle]
})<{ ownerState: TextOwnerState }>``;

export default Text;
